import React, { useEffect, useRef, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { client } from './sanityClient';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const BlogSlider = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const swiperRef = useRef(null);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "article"] {
          headline,
          subHeadline,
          coverImage {
            asset->{
              url
            }
          },
          content[] {
            ...,
            _type == "block" => {
              children[] {
                text
              }
            }
          },
          _createdAt
        }`
      )
      .then((data) => {
        const sortedBlogs = data.sort(
          (a, b) => new Date(b._createdAt) - new Date(a._createdAt)
        );
        setBlogs(sortedBlogs);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <div id='blog'>
      <div className='container'>
        <div className='section-title' data-aos='fade-down'>
          <h2>Blog</h2>
        </div>

        <Swiper
          modules={[Navigation, Pagination, A11y]}
          spaceBetween={30}
          slidesPerView={1}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          pagination={{ clickable: true }}
        >
          {blogs.map((blog, index) => (
            <SwiperSlide key={index}>
              <div
                // className='media-image'
                style={{
                  borderRadius: '20px',
                  width: '300px',
                  overflow: 'hidden',
                  margin: '0px auto 10px auto',
                }}
                data-aos='zoom-in'
              >
                <img
                  src={blog.coverImage?.asset.url}
                  alt={`Blog Cover ${index}`}
                  style={{
                    width: '100%',
                    height: 'auto',
                    objectFit: 'cover',
                  }}
                />
              </div>
              <h4
                style={{
                  marginBottom: '20px',
                }}
              >
                {blog.headline}
              </h4>
              <div
                className='custom-scroll'
                style={{
                  marginBottom: '20px',
                }}
              >
                <div className='content-container'>
                  {blog.content?.map((block, blockIndex) =>
                    block.children?.map((child, childIndex) => (
                      <p key={`${blockIndex}-${childIndex}`}>{child.text}</p>
                    ))
                  )}
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className='mobile-invisibility'>
            <div
              className='swiper-button-prev'
              onClick={() => swiperRef.current?.slidePrev()}
            ></div>
            <div
              className='swiper-button-next'
              onClick={() => swiperRef.current?.slideNext()}
            ></div>
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default BlogSlider;
