import React from 'react';

export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a
            className='navbar-brand page-scroll'
            href='#page-top'
            style={{ position: 'relative', fontSize: '24px' }}
          >
            Jerry Stephen Troyer
            {/* <br />
                <span
                  style={{
                    fontStyle: 'italic',
                    fontSize: '14px',
                    textTransform: 'lowercase',
                    position: 'absolute',
                    left: '127px',
                  }}
                >
                  author
                </span> */}
          </a>
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#page-top' className='page-scroll'>
                Home
              </a>
            </li>
            <li>
              <a href='#about' className='page-scroll'>
                About
              </a>
            </li>
            <li>
              <a href='#books' className='page-scroll'>
                Books
              </a>
            </li>
            <li>
              <a href='#blog' className='page-scroll'>
                Blog
              </a>
            </li>
            <li>
              <a href='#media' className='page-scroll'>
                Media
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll'>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
